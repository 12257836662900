// app.js

$(document).ready(function(){
    $('.product-delete').on('click', function(e){
        e.preventDefault();
        var sku = $(this).parent().parent().find('td').eq(1).text();
        var resp = confirm('Are you sure you want to delete: '+sku);

        if (resp == true)
        {
            window.location = $(this).attr('href');
        }
    });

    $('.product-search').on('submit', function(e){
        e.preventDefault();
        var form = $(this);

        var search = form.find('input[type="text"]').val();

        if(search != '')
        {
            window.location.href = '/products/search/'+search;
        }
        else
        {
            window.location.href = '/products';
        }
    });

    $('.password-update').on('click', function(){
        var disabled = $('.user').find('.disabled');
        disabled.toggleClass('active');

        if(disabled.hasClass('active'))
        {
            $(this).find('input').prop('disabled', true);
        }
        else
        {
            $(this).find('input').prop('disabled', true);
        }
    });

    $('.option-set-type').on('change', function(){
        var type = $(this).val();
        var token = $('input[name="_token"]').val();
        $.post('/options/available', {type: type, _token: token}, function(html){
            $('.option-set-options').html(html);
        });
    });

    $('.option-field').on('change', function(){
        var field = $(this).val();
        $('.option-values').hide();
        if(field == 'select')
        {
            $('.option-values').show();
        }
    });

    $('.option-value-add').on('click', function(e){
        e.preventDefault();

        var optval = $('.option-values').find('input').last();

        if(optval.val() != '')
        {
            var append = optval.clone().val('');
            append.appendTo('.option-values');
        }
    });

    $('.product-image-add').on('click', function(e){
        e.preventDefault();

        var img = $('.product-images').find('input').last();

        if(img.val() != '')
        {
            var append = img.clone().val('');
            append.appendTo('.product-images');
        }
    })

    $('.option-set-link').on('click', function(e){
        e.preventDefault();

        var set = $(this).data('set');
        var current = $('.option-set-current').data('current');

        if(set != current)
        {
            $('.option-set').fadeOut(function(){
                $('.option-set[data-optionset="'+set+'"]').fadeIn();
            });

            $('.option-set-current').data('current', set);
            $('.option-set-current').text($(this).text());
        }
    });
});
